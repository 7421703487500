

* {
  @import url("https://fonts.googleapis.com/css2?family=Italiana&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');

  padding: 0;
  margin: 0;
  box-sizing: border-box;
  max-width: auto;
  height: auto;
  overflow-x: hidden;


}

body{



///////////////////////////////////////////// 
 //----------font-size---------------------//
 ///////////////////////////////////////////// 
--font-size-XXS:6px;
--font-size-XS:10px;
--font-size-S:14px;
--font-size-M:16px;
--font-size-L:18px;
--font-size-XL:20px;
--font-size-XXL:24px;

 ///////////////////////////////////////////// 
 //----------font-color---------------------//
 ///////////////////////////////////////////// 
 --primary-color: #86ccff;
//  --primary-color: #52aaea;
 --light-navy: #6D7C99;
 --pink-color: rgb(255, 183, 195);
 --black-color: #101010;

 ///////////////////////////////////////////// 
//----------font-family---------------------//
///////////////////////////////////////////// 
 --primary-font-family: "Italiana", san-serif;
--mv-font-family:"Caveat", cursive;

}


.sectionInner{
  width: 66%;
  height: auto;
  margin: 0 auto;
}