

.mainVisual-wrap {
  background-image: url(../../public/images/bgImg.jpg);
  background-repeat: no-repeat;
  max-width: 100%;
  height: auto;
  background-position: center center;
  background-size: cover;
  /* margin: auto; */
  /* padding: 4rem 0 4rem; */
  font-family: var(--primary-font-family);
  position: relative;
  padding: 6% 0 0 0;

  .mvSakuraS1 {
    width: 3%;
    height: auto;
    position: absolute;
    top: 36%;
    right: 20%;
    animation: fall 4s linear infinite,
      rotate1 3s ease-in-out infinite alternate;
  }

  .mvSakuraS2 {
    width: 4%;
    height: auto;
    position: absolute;
    top: 13%;
    right: 19%;
    animation: fall 4s ease-in infinite,
      rotate1 6s ease-in-out infinite alternate;
  }

  .mvSakuraM {
    width: 6%;
    height: auto;
    position: absolute;
    bottom: 26%;
    left: 15%;
    animation: fall 4s ease-in-out infinite,
      rotate1 5s ease-in-out infinite alternate;
  }

  .mv-h1 {
    // font-family: 'Just Me Again Down Here', cursive;
    color: var(--black-color);
    text-align: center;
    margin: 1% auto;
    display: block;
    font-size: 67px;
    font-weight: 550;
    // animation: typing 6.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    margin: 3rem auto;
    // typing animation try area
    width: 24ch;
    animation: typing 3s steps(24), effect 0.5s step-end infinite alternate;
    white-space: nowrap;
    border-right: 3px solid;

    // typing animation try area
  }

  @keyframes typing {
    from {
      width: 0;
    }
  }

  @keyframes effect {
    50% {
      border-color: transparent;
    }
  }

  @keyframes rotate1 {
    from {
      transform: translateX(0px) rotate(0deg);
    }

    to {
      transform: translateX(200px) translateY(3px) rotate(-80deg)
        rotateX(180deg);
    }
  }

  @keyframes rotate2 {
    from {
      transform: translateX(200px) rotate(-45deg);
    }

    to {
      transform: translateX(0px) rotate(0deg);
    }
  }

  @keyframes slide-right {
    from {
      transform: translateX(-100%);
    }

    to {
      // transform: translateX(calc(50% - (1920px / 2)));
      transform: translateX(100%);
    }
  }

  // @keyframes slide-right {
  //   0% {
  //     -webkit-transform: translateY(0) translateX(0);
  //             transform: translateY(0) translateX(0);
  //   }
  //   100% {
  //     -webkit-transform: translateY(50%) translateX(50%);
  //             transform: translateY(50%) translateX(50%);
  //   }
  // }

  .mvInner {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 10%;
    margin: 3% 0 0 0;
    .mv-top {
      font-family: var(--primary-font-family);
      font-size: var(--font-size-L);
      font-weight: 600;
      margin: 0 auto 24px;
      // padding-left: 3rem;
      line-height: 3rem;
      margin-bottom: 24px;
      letter-spacing: 0.2rem;
      text-align: center;
    }

    .mv-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      // margin-left: 13rem;
      gap: 1rem;
      // margin-bottom: 6rem;
      width: 36%;

      .me-wrap {
        box-shadow: 0 0 13px #fff;
      }

      .mv-me {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(1.1);
      }
    }
  }
}

//Footer

.footer {
  background-color: #2fc4b2;
  display: flex;
  justify-content: flex-start;
  gap: 25%;
  align-items: flex-end;
  // padding-bottom: 2rem;
  padding: 0 4rem 2rem 4rem;
}

.section {
  width: 100%;
  display: flex;
  padding: 20px 0;
}

//////////////
/* tablet */
//////////////
@media screen and (max-width: 959px) {
  .mainVisual-wrap {
    height: auto;
    .mv-h1 {
      margin: 3rem auto 3rem auto;
      font-size: 26px;
      font-weight: 600;
    }

    .mvInner {
      display: flex;
      // width: 100%;
      // padding: 3rem;
      margin: 0 auto;
      flex-direction: column;
      .mv-top {
        margin: 0 auto 24px;
        // padding-left: 3rem;
        font-size: var(--font-size-M);
        font-weight: 600;
        line-height: 2rem;
        letter-spacing: 0.1rem;
      }
    }
  }

  /*--------------custom scrollbar----------------*/
  ::-webkit-scrollbar {
    display: none;
  }
}

/*smartphone */
@media screen and (max-width: 599px) {
  .mainVisual-wrap {
    .mv-h1 {
      padding: 1rem;
      font-size: 19px;
      font-weight: 600;
    }

    .mvInner {
      padding: 0 3%;

      .mv-top {
        font-size: var(--font-size-XS);
        line-height: 1.8rem;
        padding-left: 0;
      }
    }
  }
}
