.section-wrap {
  // background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;


}

.section-title {
  margin-bottom: 1rem;
  margin: 3rem 0 1rem;
  font-size: 43px;
  font-family: var( --primary-font-family);
  font-weight: normal;
  text-align: center;
}

.section-body {
  display: flex;
}


/*tablet */
@media screen and (max-width: 959px) {
  .section-title {
    font-size: var(--font-size-XXL);
   }
}


/*smartphone */
@media screen and (max-width: 599px) {
  .section-title {
   font-size: var(--font-size-XL);
  }
}