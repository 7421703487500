.Footer-wrap {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: linear-gradient(45deg, #aef3ffe0, #558eff);
  height: 50px;
  // padding: 1rem 0;
  scrollbar-width: none;
  // background-image: url('../../public/images/bgImg.jpg');
  // background-position: center center;
  // padding: 0 3rem;

  .FooterComponent {
    .FooterNav {
      display: flex;
      gap: 6rem;
      padding-left: 7rem;
      font-size: var(--font-size-XL);
      list-style-type: none;
      a {
        .FooterNavOption {
          text-decoration: none;
          color: var(--black-color);
          &:active {
            transform: scale(0.95);
            opacity: 0.8;
          }
        }
      }
    }
  }


}

/* tablet */
@media screen and  (max-width: 959px) {
  .Footer-wrap {
    height: 46px;
    .Footer-icons-links-wrap {
      gap: 0.3rem;
      .Footer-links-img {
        width: 30px;
        height: auto;
      }
    }
    .FooterComponent .FooterNav {
      padding-left: 0rem;
      gap: 2rem;
      .FooterNavOption{
        font-size: var(--font-size-M);
      }
    }
  }
}

/*smartphone */
@media screen and (max-width: 599px) {
  .Footer-wrap {
    // height: 24px;

    .Footer-icons-links-wrap {
      gap: 0.3rem;
      .Footer-links-img {
        width: 15px;
        height: auto;
      }
    }
    .FooterComponent {
      .FooterNav {
        gap: 1.6rem;
        padding: 0;
        .FooterNavOption{
          font-size: var(--font-size-S);
        }
      }
    }
  }
}
