.hamburgerButton{
    height: 100%;
    
    button{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
        height: 20px;
        width: 25px;
        margin: auto;
        background-color: transparent;
        border: none;

        span{
            display: block;
            height: 15%;
            border-radius: 2rem;
            width: 100%;
            background-color: black;
        }
    }
}

