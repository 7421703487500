


.masonry-img {
  width: 100%;
  vertical-align: bottom;
}

.masonry-wrap {
  margin: 0 auto;
  width: 66%;
  // background-color: var(--primary-color);
  column-count: 3;
  column-gap: 0;
}

@media (max-width: 800px) {
  .masonry-wrap {
    column-count: 2;
  }
}

@media (max-width: 480px) {
  .masonry-wrap {
    column-count: 1;
  }
}

.grid {
  padding: 5px;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.text {
  padding: 20px;
  background-color: #e0f2f1;
}

.pic {
  padding: 5px 0;
  background-color: #222;
  color: #fff;
}

.grid > * {
  border-radius: 2rem;
  filter: brightness(1.1);
}

.pic > *:not(img) {
  padding: 0 20px;
}

