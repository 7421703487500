.section-wrap {
  background: linear-gradient(90deg, #99e5ff, #2868ff);
  .about-sns {
    padding: 2rem 0;
    .icons-links-wrap {
      display: flex;
      gap: 1.2rem;
    }

    .links-img {
      max-width: 33px;
      max-height: 33px;
    }

    .links-img:hover {
      opacity: 0.6;
    }
  }
}

.titleSakura {
  transform-origin: center bottom;
  animation: Swaying 2s linear infinite;
  width: 3%;
}

@keyframes Swaying {
  0%,
  100% {
    transform: rotate(10deg);
  }

  50% {
    transform: rotate(-10deg);
  }
}

.aboutContainer {
  margin: auto;
  display: flex;
  gap: 6%;
  justify-content: center;
  align-items: center;

  .myself-wrap {
    flex: 3;
    font-size: var(--font-size-L);
    line-height: 1.6;
    font-family: var(--primary-font-family);
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .skill-graph {
    flex: 1;
  }
  .aboutContainer-wrap {
    height: 26%;
    width: 17%;
    margin: 10px;
    padding: 10px;
    border-radius: 50%;
    // border: 3px solid black;
    background-color: var(--secondary-color);

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    aspect-ratio: 1/1;

    &:hover {
      opacity: 0.85;
    }

    .aboutImg {
      // font-size: 60px;
      // margin: 0 0 1rem 0;
      svg {
        font-size: 66px;
      }
    }

    .aboutTitle {
      font-size: clamp(13px, 14px, 26px);
    }
  }
}

.titleWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  // gap: 3rem;
  margin: 3rem auto 1.5rem;
  padding: 1rem;
  width: 100%;
}

// .section-wrap {
//   // background-image: url('../../public/images/bg.jpg');

// }

.SkillContainer {
  width: 80%;
  min-height: 600px;
  border: 3px solid pink;
  margin: 90px;
}

.skill-graph {
min-width: 250px;
}

.skill-bar {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.skill-name {
  flex: 1;
  font-size: var(--font-size-M);
}

.bar-container {
  flex: 1.5 1;
  background-color: #fff;
  height: 20px;
  border-radius: 8px;
  margin: 0 16px;
  overflow: hidden;
}

.bar {
  height: 100%;
  background-color: var(--pink-color);
}

.percentage {
  flex: 1;
  font-size: 24px;
}

/*tablet */
@media screen and (max-width: 959px) {

  .aboutContainer {
    display: flex;
    flex-direction: column;
    gap: 66px;
}
.about-sns {
  width: 100%;
  .icons-links-wrap {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 24px;
    .links-img {
      width: 33px;
    }
  }
}
.skill-name {
  font-size: 13px;
}
}
/*smartphone */
@media screen and (max-width: 599px) {
  .aboutContainer {

    gap: 45px;
    .myself {

      font-size: var(--font-size-S);
      line-height: 1.3;
    }
    .aboutContainer-wrap {
      .aboutTitle {
        // margin: 0;
        font-size: 13px;
      }

      .aboutImg {
        svg {
          font-size: 26px;
        }
      }
    }
    .skill-name {
      font-size: var(--font-size-XS);
    }
  }
}
