.header {
	position: fixed;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 1;

	padding: 1rem;


	width: 100%;
	height: 60px;
	scrollbar-width: none;

	background: linear-gradient(45deg, #aef3ffe0, #558eff);
	background-position: top bottom;

	.HeaderLogo {
		width: 80%;
		height: auto;

		&:active {
			transform: scale(0.95);
			opacity: 0.8;
		}
	}

	.HeaderComponent {
		padding: 0 10px;
		cursor: pointer;

		.HeaderNav {
			list-style: none;
			display: flex;
			// padding: 0 5rem 0 0 ;
		}

		.HeaderNavOption {
			//
			margin: 0 20px;
			// color: white;
			font-size: var(--font-size-XL);

			&:active {
				transform: scale(0.95);
				opacity: 0.8;
			}
		}
	}

	.headerInner {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;

		.pcNavigation {
			max-width: 333px;
			width: auto;

			.HeaderNavOption {
				margin-right: 1rem;
				text-decoration: none;
				color: var(--black-color);
			}

		}
	}
}

.hmbnavigation { display: none}

.mobileDrawer { display: none }

//////////////
/* tablet */
//////////////
@media (max-width: 959px) {
	.headerInner {
		.pcNavigation { display: none }
	}

	.mobileDrawer { display: block }

	.hmbnavigation {
		display: block;

		.nav-inner {
			position: fixed;
			height: 100vh;
			width: 100vw;
			z-index: 2;
			background-color: rgba(0, 0, 0, 0.8);
			color: #fff;
			text-align: center;
			line-height: 3rem;
			padding: 25%;
			cursor: pointer;

			.hmb-a-wrap {
				display: flex;
				flex-direction: column;

				.hmb-a {
					color: #fff;
					text-decoration: none;
				}
			}

			.hmb-icons-links-wrap {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 1rem;
				padding-right: 0.3rem;
				margin-top: 2rem;
			
				.Footer-links-img {
					max-width: 33px;
					max-height: 33px;
					color: #fff;
			
					&:hover {
						opacity: 0.6;
					}
				}
			}
		}		
	}
}
