.section-wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.worksContainer {
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1rem 0;

  .workContainer-wrap {
    // min-height: 430px;
    margin: 10px;
    padding: .5rem  ;
    height: fit-content;
    border-radius: 1rem;
    background: linear-gradient(#fff, #ffd7f1);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .workImage {
      margin: 15px;
      // width: 230px;
      // height: 230px;
      max-width: 80%;
      max-height: 60%;
      width: auto;
      height: auto;
      object-fit: cover;
      // aspect-ratio: 1/1;
      // border: 1px solid black;
      // border-radius: 1rem;
    }

    .workTitle {
      font-size: var(--font-size-M);
    }

    .workExplanation {
      font-size: var(--font-size-M);
      margin: 0 auto;
      padding: 5% 15% 0;
      text-align: center;
    }

    .workButtons {
      margin: 20px;
      font-size: 25px;
      color: var(--black);

      .workButton {
        svg{
          color: var(--black-color);
        }
        margin: 0 15px;
      }
    }

    .workButtons:hover {
      opacity: 0.6;
    }
  }
}

//////////////
/* tablet */
//////////////
@media screen and (max-width: 959px) {
  .worksContainer {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    .workContainer-wrap {
      .workExplanation {
        font-size: var(--font-size-S);
      }
    }
  }
}

/*smartphone */
@media screen and (max-width: 599px) {
  .worksContainer {
    display: block;
    .workContainer-wrap {
      .workExplanation {
        font-size: var(--font-size-S);
      }
    }
  }
}
